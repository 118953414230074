<template>
  <div class="modal-edit-content">
    <base-title size="l" mB="xl">{{ this.title }}</base-title>
    <base-textarea v-if="editedContent" v-model="editedContent" mB="l"></base-textarea>
    <dropzone v-if="editedImage" @emitFile="confirmEdit"></dropzone>
    <base-button v-if="editedContent" @click="confirmEdit">Confirm edit</base-button>
    <span v-if="uploading">Uploading image...</span> <!-- VIIMEISTELE -->
  </div>
</template>

<script>
export default {
  name: 'modalEditContent',

  components: {
    dropzone: () => import('@/components/dropZone')
  },

  props: ['modalData'],

  data() {
    return {
      editedContent: '',
      editedImage: '',
      uploading: false
    }
  },

  created() {
    this.editedContent = this.modalData.text
    this.editedImage = this.modalData.image
  },

  computed: {
    title() {
      if (this.editedImage) {
        return 'Change image'
      }
      return 'Edit content'
    }
  },

  methods: {
    async confirmEdit(imageData) {
      const path = this.modalData.path
      let content = this.editedContent
      
      if (imageData && imageData.dataURL) {
        const imagePath = 'images/' +path.property.replace('.', '/') +'/'
        content = imageData.name
        this.uploading = true
        await this.$api.uploadDataToStorage(imageData.dataURL, imagePath + imageData.name)
        .then(() => {
          let previousFile = this.editedImage.substring(this.editedImage.lastIndexOf('/') +1)
          this.$api.deleteFileFromStorage(imagePath + previousFile)
        })
        this.uploading = false        
      }

      this.$store.dispatch('SET_STATE', {
        data: content,
        path: path.collection + '.' + path.document + '.' + path.property
      })

      this.$api.updateData(path.collection, path.document, { [path.property]: content })
      this.$emit('closeModal')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-edit-content {
}
</style>
